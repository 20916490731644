import "./src/styles/global.scss"

export const onRouteUpdate = ({ location, prevLocation }) => {
  setTimeout(() => {
    // console.log('new pathname', location.pathname, window.document.title)
    // console.log('old pathname', prevLocation ? prevLocation.pathname : null)
    if (window.analytics) {
      window.analytics.track('website:viewed')
    }
    if (window.tianalytics) {
      window.tianalytics && window.tianalytics.page({gdpr_strict:!0})
    }
    if (window._hsq) {
      window._hsq.push(['setPath', location.pathname])
      window._hsq.push(['trackPageView'])
    }
  }, 500)
}